body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn,
.sp-container button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  border-radius: 3px;
  color: #8f9cc0;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.538;
  line-height: 1.5;
  min-height: 38px;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.btn-white,
.btn-white.focus,
.btn-white:focus,
.btn-white:hover {
  background-color: #fff;
  background-image: none;
  border-color: #fff;
  color: #424e79;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.card {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid #e8e8f7;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  cursor: pointer;
  min-height: 200px;
}

.card:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition-duration: 0.3s;
}
.custom-card {
  border: 0;
  box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.custom-card {
  box-shadow: 0 10px 30px 0 rgba(98, 89, 202, 0.05);
}

.custom-card > a {
  cursor: pointer !important;

  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.custom-card > div {
  cursor: pointer;
}
.custom-card > div > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-card a > div > div > label {
  cursor: pointer !important;
}
.custom-card > div > div > label > h5 {
  cursor: pointer !important;
}
.custom-card > div > div > label > img {
  cursor: pointer !important;
}

.mobile-responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .custom-card {
    border: 0;
    box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
    /* margin-bottom: 10px; */
    cursor: pointer;
  }
  .mobile-responsive-width {
    width: 100%;
    font-size: small;
    font-size: 11px !important;
  }
}

/* page header wqork */

.page-header {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
  margin: 1.8rem 0 1.5rem 0;
  border-radius: 7px;
  width: 100%;
}
.page-header .breadcrumb {
  background: none;
  padding-left: 0 !important;
  padding: 0;
  margin-bottom: 0;
}
/* .page-header .breadcrumb-item a {
  color: #8f9cc0;
}
.page-header .breadcrumb-item.active {
  color: #7787bc;
} */
.page-header .breadcrumb {
  margin-bottom: 1rem;
}
.main-content.side-content .page-header,
.main-content .page-header {
  padding-top: 63px !important;
}

@media (max-width: 767px) {
  .main-content.side-content .page-header,
  .main-content .page-header {
    padding-top: 120px !important;
  }
}
@media (max-width: 991px) {
  .page-header {
    display: block;
    padding-top: 60px;
  }
}

/* page header work end */

/* bttons work */

.btn-primary {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}
.btn-primary:hover {
  color: #fff;
  background-color: var(--primary-bg-hover);
  border-color: var(--primary-bg-hover);
}
.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--primary-bg-hover);
  border-color: var(--primary-bg-hover);
  box-shadow: 0 0 0 1px var(--primary07);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: var(--primary08);
  border-color: var(--primary08);
}
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary08);
  border-color: var(--primary08);
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 1px var(--primary07);
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px var(--primary07);
}

.home-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-cards .feature_item {
  width: 12rem;
}

@media (max-width: 420px) {
  .feature_item p {
    display: none;
  }
  .home-cards .feature_item {
    width: 140px;
  }
  .home-cards .feature_item > h4 {
    font-size: 16px !important;
  }

  .home-cards .feature_item > img {
    display: none;
  }
}

/* Cards on Hover Effect CSS */
.hvr-shadow-radial {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-shadow-radial:before,
.hvr-shadow-radial:after {
  pointer-events: none;
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  height: 5px;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.hvr-shadow-radial:before {
  bottom: 100%;
  background: radial-gradient(
    ellipse at 50% 200%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:after {
  top: 100%;
  background: radial-gradient(
    ellipse at 50% -50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:hover:before,
.hvr-shadow-radial:focus:before,
.hvr-shadow-radial:active:before,
.hvr-shadow-radial:hover:after,
.hvr-shadow-radial:focus:after,
.hvr-shadow-radial:active:after {
  opacity: 1;
}

/* Feature Area css
============================================================================================ */
.feature_item {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
  border-radius: 11px;
}

.feature_item img {
  /* margin-bottom: 35px; */
  margin-bottom: 15px;
}

.feature_item h4 {
  color: #222222;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  /* margin-bottom: 15px; */
  margin-bottom: 5px;
}

.feature_item p {
  margin-bottom: 0px;
}

.coloring {
  background-color: black;
}

.fs-8 {
  font-size: 11px !important;
}

.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(1) {
  background-color: "orange" !important;
  color: #90ee90 !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(1):hover {
  background-color: "orange" !important;
  color: green !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(2) {
  background-color: "orange" !important;
  color: #fff5f8 !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(2):hover {
  background-color: "orange" !important;
  color: #f1416c !important;
}
.material-table-parent-div > div {
  border-radius: 15px !important;
}

.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th {
  background-color: #002564;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:hover {
  color: lightgray !important;
}

.textdecorationoflink > ol > li > a:nth-child(1) {
  text-decoration: none;
  cursor: default !important;
  color: black;
}

button > .MuiIconButton-label > .material-icons {
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  color: #a1a5b7;
  background-color: #f5f8fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.3em + 1rem + 1px);
  width: calc(1.3em + 1rem + 1px);
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.325rem;
}
button > .MuiIconButton-label > .material-icons:hover {
  color: #009ef7;
}

.MuiIconButton-root:hover {
  background: none !important;
  background-color: none !important;
}

.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span {
  background-color: #e8fff3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span:hover {
  background-color: #c9ffe3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span {
  background-color: #fff5f8 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span:hover {
  background-color: #ffd6e3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span {
  background-color: #f1faff !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span:hover {
  background-color: #f1faff !important;
  color: #009ef7;
  border-radius: 50%;
}

.MuiTab-textColorPrimary {
  color: #90ee90;
}
.Mui-selected {
  color: #90ee90;
}

.material-table-parent-div {
  overflow-x: auto;
}

@media only screen and (min-width: 500px) {
  .material-table-parent-div > div {
    width: 397px;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}
@media only screen and (min-width: 400px) {
  .material-table-parent-div > div {
    width: 390px;
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .material-table-parent-div > div {
    width: 343px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .handlemodel > div {
    width: 390px;
  }
  .MuiTable-root > tbody > tr > td {
    padding: 5px 2px !important;
  }
  .MuiTable-root > tbody > tr > td > div > button {
    padding: 5px !important;
  }
  .material-table-parent-home
    > div
    > div
    > div
    > div
    > div
    > table
    > tbody
    > tr
    > td {
    padding: 15px 8px !important;
  }
}
@media only screen and (min-width: 1100px) {
  .handlemodel > div {
    width: 1050px;
  }
}
.modal-size > .modal-dialog > .modal-content {
  /* max-width: none !important; */
  /* overflow: scroll; */
  /* width: 700px !important; */
  /* width: 100% !important; */
  height: 90vh;
}

.forscrollbar ::-webkit-scrollbar {
  background-color: white !important;
  border-radius: 50px !important;
  border: 1px solid #f8f8f8;
  width: 10px !important;
}

.forscrollbar ::-webkit-scrollbar-thumb {
  background-color: #002564 !important;
  border-radius: 30px;
}

.material-table-parent-div > div > table > tfoot > tr > td > div {
  width: 300px;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .material-table-parent > div {
    width: 295px;
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .material-table-parent > div {
    width: 100%;
  }
}

.material-table-parent > div > table > tfoot > tr > td > div {
  width: 200px;
}
.dropdownsizes {
  width: 500px !important;
}
.material-table-parent-div > div {
  border: 1px solid #ededed;
  width: 100%;
  /* height: 480px; */
}
.material-table-defect-modal {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .onsmallscreen {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .smallscreensizes {
    /* height: 1179px !important; */
    background-size: cover !important;
  }
}

@media only screen and (max-width: 400px) {
  .modelhandling {
    height: 19px !important;
  }
}

.fade {
  /* height: 100% !important; */
  /* width: 100% !important; */
  width: 100% !important;
}
.fade > .modal-dialog > .modal-content {
  width: 100% !important;
}
@media only screen and (max-width: 400px) {
  .fade > .modal-dialog > .modal-content {
    /* width:343px !important */
    width: 100% !important;
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .fade > .modal-dialog > .modal-content {
    width: 700px !important;
  }
}
.Boxshedow {
  box-shadow: 2px 1px #888888 !important;
}
/* .Boxshedow :hover{
  background-color: #002564 !important;
  color: white;
} */

/* Code for Download and Export Button */

.MTableToolbar-actions-8 > div > div > span > button > span > span {
  color: white !important;
  background-color: green !important;
}

.forborder {
  border: 0px;
}
.coloring {
  background-color: #002564 !important;
  color: #fff !important;
}

.dashboard-btn3:hover {
  background-color: #002564 !important;
  text-decoration: none;
  margin-left: 25px;
  color: white;
  transition: all 1s ease-in-out;
}

.btn-hover:hover {
  color: white;
}

.dashboard-btn3 > div > :hover {
  background-color: #002564 !important;
  text-decoration: none;
  color: white;
}

.marginecommercebutton {
  margin-left: 5px;
}

.analytic-btn :hover {
  /* background-color: #D0D0D0 !important; */
  margin-left: 3px;
}

.addbutton :hover {
  cursor: pointer;
}

.check {
  width: 10px;
  height: 10px;
  color: red;
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.sidemenu-logo {
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  justify-content: center;
  padding: 13.4px 21px;
  background-color: "#002564";
  font-size: 24px;
  font-weight: bold;
}

@-webkit-keyframes example {
  0% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  25% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  50% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  75% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  100% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
}

@keyframes example {
  0% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  25% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  50% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  75% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
  100% {
    color: #002564;
    left: 0px;
    top: 0px;
  }
}

@media screen and (min-width: 600px) {
  .screen-lg-menu-btn-hide > button {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .logosize {
    width: 90px;
  }
  .forborder {
    background-color: #002564 !important;
  }
}

@media screen and (max-width: 992px) {
  .dropdownsize {
    margin-left: 92px;
    margin: 70px 0px 0px 100px !important;
  }
}
@media screen and (max-width: 570px) and (min-width: 370px) {
  .dropdownsizesmaller {
    margin: 0px;
    width: 250px !important;
  }
}
.drawerbackground {
  background-color: #002564 !important;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #002564 !important;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .drawer {
    background-color: green !important;
  }
}

.forborder{
    border: 0px;
}
.coloring{
    background-color: #280137;
}

.dashboard-btn  a:hover {
    background-color: #D0D0D0 !important;
    margin-left: 3px;
}

.marginecommercebutton{
    margin-left: 5px;
}

.analytic-btn :hover{
    /* background-color: #D0D0D0 !important; */
    margin-left: 3px;

}

.addbutton :hover{
   cursor: pointer;
}


.check {
    width: 10px;
    height: 10px;
    color: red;
    position: relative;
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes example {
    0%   {color:#002564; left:0px; top:0px;}
    25%  {color:white; left:0px; top:0px;}
    50%  {color:#002564; left:0px; top:0px;}
    75%  {color:white; left:0px; top:0px;}
    100% {color:#002564; left:0px; top:0px;}
  }
  
  @keyframes example {
    0%   {color:#002564; left:0px; top:0px;}
    25%  {color:white; left:0px; top:0px;}
    50%  {color:#002564; left:0px; top:0px;}
    75%  {color:white; left:0px; top:0px;}
    100% {color:#002564; left:0px; top:0px;}
  }



 
  @media screen and (max-width: 400px ) {
    .logosize {width: 90px}
 
  }

  @media screen and (max-width: 992px ) {
    .dropdownsize {margin-left: 92px;
                    margin: 70px 0px 0px 100px !important;
    
    }
 
  }
  @media screen and (max-width: 570px ) and (min-width: 370px) {
    .dropdownsizesmaller {margin: 0px; width:250px   !important;

    
    }
 
  }
