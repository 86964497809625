body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn,
.sp-container button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  border-radius: 3px;
  color: #8f9cc0;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.538;
  line-height: 1.5;
  min-height: 38px;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.btn-white,
.btn-white.focus,
.btn-white:focus,
.btn-white:hover {
  background-color: #fff;
  background-image: none;
  border-color: #fff;
  color: #424e79;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.card {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid #e8e8f7;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  cursor: pointer;
  min-height: 200px;
}

.card:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition-duration: 0.3s;
}
.custom-card {
  border: 0;
  box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.custom-card {
  box-shadow: 0 10px 30px 0 rgba(98, 89, 202, 0.05);
}

.custom-card > a {
  cursor: pointer !important;

  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.custom-card > div {
  cursor: pointer;
}
.custom-card > div > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-card a > div > div > label {
  cursor: pointer !important;
}
.custom-card > div > div > label > h5 {
  cursor: pointer !important;
}
.custom-card > div > div > label > img {
  cursor: pointer !important;
}

.mobile-responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .custom-card {
    border: 0;
    box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
    /* margin-bottom: 10px; */
    cursor: pointer;
  }
  .mobile-responsive-width {
    width: 100%;
    font-size: small;
    font-size: 11px !important;
  }
}

/* page header wqork */

.page-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0 1.5rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
  margin: 1.8rem 0 1.5rem 0;
  border-radius: 7px;
  width: 100%;
}
.page-header .breadcrumb {
  background: none;
  padding-left: 0 !important;
  padding: 0;
  margin-bottom: 0;
}
/* .page-header .breadcrumb-item a {
  color: #8f9cc0;
}
.page-header .breadcrumb-item.active {
  color: #7787bc;
} */
.page-header .breadcrumb {
  margin-bottom: 1rem;
}
.main-content.side-content .page-header,
.main-content .page-header {
  padding-top: 63px !important;
}

@media (max-width: 767px) {
  .main-content.side-content .page-header,
  .main-content .page-header {
    padding-top: 120px !important;
  }
}
@media (max-width: 991px) {
  .page-header {
    display: block;
    padding-top: 60px;
  }
}

/* page header work end */

/* bttons work */

.btn-primary {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}
.btn-primary:hover {
  color: #fff;
  background-color: var(--primary-bg-hover);
  border-color: var(--primary-bg-hover);
}
.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--primary-bg-hover);
  border-color: var(--primary-bg-hover);
  box-shadow: 0 0 0 1px var(--primary07);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: var(--primary08);
  border-color: var(--primary08);
}
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary08);
  border-color: var(--primary08);
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 1px var(--primary07);
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px var(--primary07);
}

.home-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-cards .feature_item {
  width: 12rem;
}

@media (max-width: 420px) {
  .feature_item p {
    display: none;
  }
  .home-cards .feature_item {
    width: 140px;
  }
  .home-cards .feature_item > h4 {
    font-size: 16px !important;
  }

  .home-cards .feature_item > img {
    display: none;
  }
}

/* Cards on Hover Effect CSS */
.hvr-shadow-radial {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-shadow-radial:before,
.hvr-shadow-radial:after {
  pointer-events: none;
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  height: 5px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-shadow-radial:before {
  bottom: 100%;
  background: -webkit-radial-gradient(
    50% 200%,
    ellipse,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at 50% 200%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:after {
  top: 100%;
  background: -webkit-radial-gradient(
    50% -50%,
    ellipse,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at 50% -50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:hover:before,
.hvr-shadow-radial:focus:before,
.hvr-shadow-radial:active:before,
.hvr-shadow-radial:hover:after,
.hvr-shadow-radial:focus:after,
.hvr-shadow-radial:active:after {
  opacity: 1;
}

/* Feature Area css
============================================================================================ */
.feature_item {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
  border-radius: 11px;
}

.feature_item img {
  /* margin-bottom: 35px; */
  margin-bottom: 15px;
}

.feature_item h4 {
  color: #222222;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  /* margin-bottom: 15px; */
  margin-bottom: 5px;
}

.feature_item p {
  margin-bottom: 0px;
}
