.coloring {
  background-color: black;
}

.fs-8 {
  font-size: 11px !important;
}

.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(1) {
  background-color: "orange" !important;
  color: #90ee90 !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(1):hover {
  background-color: "orange" !important;
  color: green !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(2) {
  background-color: "orange" !important;
  color: #fff5f8 !important;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > div
  button:nth-child(2):hover {
  background-color: "orange" !important;
  color: #f1416c !important;
}
.material-table-parent-div > div {
  border-radius: 15px !important;
}

.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th {
  background-color: #002564;
}
.material-table-parent-div
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:hover {
  color: lightgray !important;
}

.textdecorationoflink > ol > li > a:nth-child(1) {
  text-decoration: none;
  cursor: default !important;
  color: black;
}

button > .MuiIconButton-label > .material-icons {
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: #a1a5b7;
  background-color: #f5f8fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.3em + 1rem + 1px);
  width: calc(1.3em + 1rem + 1px);
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.325rem;
}
button > .MuiIconButton-label > .material-icons:hover {
  color: #009ef7;
}

.MuiIconButton-root:hover {
  background: none !important;
  background-color: none !important;
}

.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span {
  background-color: #e8fff3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span:hover {
  background-color: #c9ffe3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span {
  background-color: #fff5f8 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span:hover {
  background-color: #ffd6e3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span {
  background-color: #f1faff !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span:hover {
  background-color: #f1faff !important;
  color: #009ef7;
  border-radius: 50%;
}

.MuiTab-textColorPrimary {
  color: #90ee90;
}
.Mui-selected {
  color: #90ee90;
}

.material-table-parent-div {
  overflow-x: auto;
}

@media only screen and (min-width: 500px) {
  .material-table-parent-div > div {
    width: 397px;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}
@media only screen and (min-width: 400px) {
  .material-table-parent-div > div {
    width: 390px;
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .material-table-parent-div > div {
    width: 343px;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .material-table-parent-div > div {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .handlemodel > div {
    width: 390px;
  }
  .MuiTable-root > tbody > tr > td {
    padding: 5px 2px !important;
  }
  .MuiTable-root > tbody > tr > td > div > button {
    padding: 5px !important;
  }
  .material-table-parent-home
    > div
    > div
    > div
    > div
    > div
    > table
    > tbody
    > tr
    > td {
    padding: 15px 8px !important;
  }
}
@media only screen and (min-width: 1100px) {
  .handlemodel > div {
    width: 1050px;
  }
}
.modal-size > .modal-dialog > .modal-content {
  /* max-width: none !important; */
  /* overflow: scroll; */
  /* width: 700px !important; */
  /* width: 100% !important; */
  height: 90vh;
}

.forscrollbar ::-webkit-scrollbar {
  background-color: white !important;
  border-radius: 50px !important;
  border: 1px solid #f8f8f8;
  width: 10px !important;
}

.forscrollbar ::-webkit-scrollbar-thumb {
  background-color: #002564 !important;
  border-radius: 30px;
}

.material-table-parent-div > div > table > tfoot > tr > td > div {
  width: 300px;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .material-table-parent > div {
    width: 295px;
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .material-table-parent > div {
    width: 100%;
  }
}

.material-table-parent > div > table > tfoot > tr > td > div {
  width: 200px;
}
.dropdownsizes {
  width: 500px !important;
}
.material-table-parent-div > div {
  border: 1px solid #ededed;
  width: 100%;
  /* height: 480px; */
}
.material-table-defect-modal {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .onsmallscreen {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .smallscreensizes {
    /* height: 1179px !important; */
    background-size: cover !important;
  }
}

@media only screen and (max-width: 400px) {
  .modelhandling {
    height: 19px !important;
  }
}

.fade {
  /* height: 100% !important; */
  /* width: 100% !important; */
  width: 100% !important;
}
.fade > .modal-dialog > .modal-content {
  width: 100% !important;
}
@media only screen and (max-width: 400px) {
  .fade > .modal-dialog > .modal-content {
    /* width:343px !important */
    width: 100% !important;
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .fade > .modal-dialog > .modal-content {
    width: 700px !important;
  }
}
.Boxshedow {
  box-shadow: 2px 1px #888888 !important;
}
/* .Boxshedow :hover{
  background-color: #002564 !important;
  color: white;
} */

/* Code for Download and Export Button */

.MTableToolbar-actions-8 > div > div > span > button > span > span {
  color: white !important;
  background-color: green !important;
}
