.forborder{
    border: 0px;
}
.coloring{
    background-color: #280137;
}

.dashboard-btn  a:hover {
    background-color: #D0D0D0 !important;
    margin-left: 3px;
}

.marginecommercebutton{
    margin-left: 5px;
}

.analytic-btn :hover{
    /* background-color: #D0D0D0 !important; */
    margin-left: 3px;

}

.addbutton :hover{
   cursor: pointer;
}


.check {
    width: 10px;
    height: 10px;
    color: red;
    position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  
  @keyframes example {
    0%   {color:#002564; left:0px; top:0px;}
    25%  {color:white; left:0px; top:0px;}
    50%  {color:#002564; left:0px; top:0px;}
    75%  {color:white; left:0px; top:0px;}
    100% {color:#002564; left:0px; top:0px;}
  }



 
  @media screen and (max-width: 400px ) {
    .logosize {width: 90px}
 
  }

  @media screen and (max-width: 992px ) {
    .dropdownsize {margin-left: 92px;
                    margin: 70px 0px 0px 100px !important;
    
    }
 
  }
  @media screen and (max-width: 570px ) and (min-width: 370px) {
    .dropdownsizesmaller {margin: 0px; width:250px   !important;

    
    }
 
  }